import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs, H4 } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getShowOrder } from '../../../CommonURL';
import { getDataWithToken } from '../../../Utils/restUtils';
import { ExtendDays, Show } from '../../../Constant';
import SearchList from '../../../CommonElements/Search';
import Transparentspinner from '../../Common/loader/Transparentspinner';
import PopupDays from '../../Companies/companies/PopupDays';
import Popup from '../../Common/Popup/Popup';

const InvoiceTable = () => {
    const navigate = useNavigate();
    const [searchKeyword, setSearchKeyword] = useState('');
    const [invoiceData, setInvoiceData] = useState([]);
    const [invoiceList, setInvoiceList] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [orderId, setOrderId] = useState('');
    const [modal, setModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }

    const InvoiceDataColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            sortable: true,
            width: '50px',
            wrap: true
        },
        {
            name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            center: true,
            width: '55px',

        },
        {
            name: <H4>Invoice No</H4>,
            selector: row => <Link to={`${process.env.PUBLIC_URL}/companies/company-list/userProfile/invoice?id=${row.User_id}&order_id=${row.Id}`}>{row.Invoice_no}</Link>,
            sortable: true,
            width: '100px',
            wrap: true
        },
        {
            name: <H4>Invoiced Date</H4>,
            selector: row => row.Invoiced_at,
            sortable: true,
            width: '130px',
            wrap: true
        },
        {
            name: <H4>End Date</H4>,
            selector: row => row.End_at,
            sortable: true,
            width: '110px',
            wrap: true
        },
        {
            name: <H4>User Name</H4>,
            selector: row => row.User_name,
            sortable: true,
            width: '150px',
            wrap: true
        },
        {
            name: <H4>Company Name</H4>,
            selector: row => row.Company_name,
            sortable: true,
            width: '150px',
            wrap: true
        },
        {
            name: <H4>Extend Due Days</H4>,
            selector: row => row.Expire_days,
            sortable: true,
            width: '150px',
            wrap: true
        },
        {
            name: <H4>Amount</H4>,
            selector: row => <span className=' fw-bolder billerq_color'>₹ {row.Amount}</span>,
            sortable: true,
            width: '90px',
            wrap: true,
        },
        {
            name: <H4>Balance</H4>,
            selector: row => <span className=' fw-bolder billerq_color'>₹ {row.Amount}</span>,
            sortable: true,
            width: '110px',
            wrap: true
        },
        {
            name: <H4>Payment Status</H4>,
            selector: row => row.Payment_status,
            sortable: true,
            width: '90px',
            wrap: true,
            cell: row => (
                <span
                    className={`badge badge-${row.Payment_status}`}
                >
                    {row.Payment_status}
                </span>
            ),
        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Status,
            sortable: true,
            width: '90px',
            wrap: true,
            cell: row => (
                <span
                    className={`badge badge-${row.Status}`}
                >
                    {row.Status}
                </span>
            ),
        },
    ];

    useEffect(() => {
        invoiceDataTable(1); // do not put await here
    }, [searchKeyword]);


    const viewForm = (row) => {
        setModal(!modal);
        setOrderId(row);
    };

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
        const redirectToView = () => {
            const editFormURL = `${process.env.PUBLIC_URL}/companies/company-list/userProfile/invoice?id=${row.User_id}&order_id=${row.Id}`;
            navigate(editFormURL);
        };

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    <DropdownItem style={toggleStyle} onClick={() => redirectToView()}>{Show}</DropdownItem>
                    {row.comment === 'subscription-cost' &&
                        <DropdownItem style={toggleStyle} onClick={() => viewForm(row)}>Extend Days</DropdownItem>
                    }
                    {/* <DropdownItem style={{ padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px', margin: '0' }} onClick={() => deleteCompanyList()}>{Delete}</DropdownItem> */}
                </DropdownMenu>
            </Dropdown >
        );
    };


    const filteredItems = invoiceData.filter(
        (item) => item.industry_name && item.industry_name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            item.description && item.description.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    const updateSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    };

    const handlePageChange = page => {
        invoiceDataTable(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        invoiceDataTable(page, newPerPage);
        setPerPage(newPerPage);
    };

    const invoiceDataTable = async (page, size = perPage) => {
        setLoading(true);
        const response = await getDataWithToken(getShowOrder + `?page=${page}&page_length=${size}`);
        setResetPaginationToggle(!resetPaginationToggle);
        setInvoiceData(response.data.data);
        let serialNo = (page - 1) * size + 1;
        if (searchKeyword === '') {
            setInvoiceList(response.data.data.map((e, index) => {
                return {
                    Si: serialNo + index,
                    Invoice_no: <span>{e.invoice_prefix} {e.invoice_number}</span>,
                    Invoiced_at: e.invoice_date,
                    End_at: e.end_date,
                    User_name: e.user_name,
                    Company_name: e.company_name,
                    Amount: e.amount,
                    Balance: e.balance,
                    Payment_status: e.payment_status,
                    Status: e.order_status,
                    Id: e.id,
                    User_id: e.user_id,
                    comment: e.comments,
                    Expire_days: e.skip_due,
                }
            }))
        } else {
            setInvoiceList(filteredItems.map((e, index) => {
                return {
                    Si: serialNo + index,
                    Invoice_no: <span>{e.invoice_prefix} {e.invoice_no}</span>,
                    Invoiced_at: e.invoice_date,
                    Due_at: e.due_date,
                    Company_id: e.industry_type_id,
                    Company_name: e.industry_name,
                    Subscription: e.package_name,
                    Amount: e.sub_total,
                    Created_at: e.created_at,
                    Status: e.status,
                }
            }))
        }
        setTotalRows(response.data.total);
        setLoading(false);
    };
    
    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle='Invoice' parent="Configuration" title="Invoice" />
            <Card>
                <CardHeader>
                    <H3>Invoice List</H3>
                </CardHeader>
                <div style={{ paddingLeft: '22px', marginRight: '22px', marginBottom: '-10px' }}>
                    <div style={{ float: 'left' }}>
                        <SearchList updateSearchKeyword={updateSearchKeyword} />
                    </div>
                    <div style={{ float: 'right' }}>
                        {/* <Link to={`${process.env.PUBLIC_URL}/page-layout/footer-light`} className="btn btn-primary">{Add}</Link> */}
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={InvoiceDataColumns}
                            data={invoiceList}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                        />
                    </div>
                    <Popup isOpen={modal} title={ExtendDays} toggler={viewForm}>
                        <PopupDays orderId={orderId} setModal={setModal} />
                    </Popup>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default InvoiceTable;