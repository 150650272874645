import React, { useState, useEffect, Fragment } from "react";
import { Col, Card, CardHeader, CardBody } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs } from '../../../AbstractElements';
import { getPaymentList } from '../../../CommonURL';
import { getDataWithToken } from '../../../Utils/restUtils';
import { PaymentColumns } from '../../../Data/MockTable/TempData';
import SearchList from '../../../CommonElements/Search';
import Transparentspinner from "../../Common/loader/Transparentspinner";

const PaymentTable = () => {
    const [tempList, setTempList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        fetchUsers(1);
    }, [searchKeyword]);

    const updateSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    };

    const fetchUsers = async (page, size = perPage) => {
        setLoading(true);
        const response = await getDataWithToken(getPaymentList + `?page=${page}&page_length=${size}&search=&industry_id=`);
        setResetPaginationToggle(!resetPaginationToggle);
        let serialNo = (page - 1) * size + 1;
        if (searchKeyword === '') {
            setTempList(response.data.data.map((e, index) => {
                return {
                    Si: serialNo + index,
                    Name: <span>{e.user_name}</span>,
                    Invoice_no: e.invoice_number,
                    Txn_id: e.transaction_id,
                    Industry: e.industry_name,
                    Payment_method: e.payment_method_name,
                    Date: e.payment_date,
                    Amount: e.paid_amount,
                    Status: e.payment_status,
                }
            }))
            setLoading(false);
        } else {
            setTempList(filteredItems.map((e, index) => {
                return {
                    Si: index + 1,
                    Name: <span>{e.first_name} {e.last_name}</span>,
                    Email: e.email,
                    Mobile_Number: e.mobile,
                    Industry: e.industry_name,
                    State: e.state,
                    Country: e.country,
                }
            }))
        }
        setTotalRows(response.data.total);
        setLoading(false);
    };


    const handlePageChange = page => {
        fetchUsers(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchUsers(page, newPerPage);
        setPerPage(newPerPage);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle='Payment' parent="Companies" title="Payment" />
            <Card>
                <CardHeader>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <H3>Payment List</H3>
                        <SearchList updateSearchKeyword={updateSearchKeyword} />
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={PaymentColumns}
                            data={tempList}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );


};

export default PaymentTable;