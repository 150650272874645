import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Btn } from '../../AbstractElements';
import { AddPayments, Cancel, Print, ThermalPrint, share } from '../../Constant';
import ItemDescription from './ItemDescription';
import { Col, Spinner } from 'reactstrap';
import { useReactToPrint } from 'react-to-print';
import { useLocation, useNavigate } from 'react-router';
import Transparentspinner from '../../Components/Common/loader/Transparentspinner';
import { getGeneratePayment, getInvoice, getMakePayment } from '../../CommonURL';
import * as authServices from "../../Utils/authServices";
import { postDataWithoutToken, postMasterToken } from '../../Utils/restUtils';
import { toast } from 'react-toastify';

const PrintComponent = () => {  
    const location = useLocation();
    const searchParams = location.search;
    const [loading, setLoading] = useState(false)
    const params = new URLSearchParams(searchParams);
    const userId = params.get("order_id");
    const [invoiceDetail, setInvoiceDetail] = useState({});
    const [statusColor, setStatusColor] = useState('');
    const navigate = useNavigate();
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        getInvoiceData();
    }, [])

    const getInvoiceData = async () => {
        setLoading(true)
        const data = await postDataWithoutToken(getInvoice + `/${userId}`);
        if (data.status === 200) {
            const userToken = authServices.getUser()?.userToken;
            const userType = authServices.getUser()?.userType;
            authServices.setUser(userToken,userType,data.data.data.token);
            setInvoiceDetail(data.data.data);
            if (data.data.data.payment_status === "pending") {
                setStatusColor('#dc0808');
            } else if (data.data.data.payment_status === 'paid') {
                setStatusColor('#67c100');
            } else if (data.data.data.payment_status === 'paschal') {
                setStatusColor('#e56809');
            } else {
                setStatusColor('#534686');
            }
        }
        setLoading(false)
    }

    const handlePayment = async () => {
        setLoading(true);
        const result = await postMasterToken(getGeneratePayment, { order_id: userId });
        if (result.status === 200) {
            if (result.data.status === true) {
                const { id: orderId, email: email, name: name, mobile: mobile, amount: amount, currency: currency } = result.data.data;
                if (amount === "") {
                    alert("please enter amount");
                } else {
                    var options = {
                        key: "rzp_test_eEEdY8dYGrVtOY",
                        key_secret: "gSqBKBUEXK3f8xoOwuu5XlGm",
                        amount: amount.toString(),
                        currency: currency,
                        name: "BillerQ",
                        description: "for testing purpose",
                        order_id: orderId,
                        handler: async function (response) {
                            const data = {
                                orderCreationId: orderId,
                                razorpayPaymentId: response.razorpay_payment_id,
                                razorpayOrderId: response.razorpay_order_id,
                                razorpaySignature: response.razorpay_signature,
                            };
                            setLoading(true);
                            const payment = await postMasterToken(getMakePayment, data);
                            if (payment.status === 200) {
                                if (payment.data.status === true) {
                                    toast.success(payment.data.message);
                                    getInvoiceData();
                                    setLoading(false);
                                } else {
                                    toast.error(payment.data.message);
                                    setLoading(false);
                                }
                            }
                        },
                        prefill: {
                            name: name,
                            email: email,
                            contact: mobile
                        },
                        notes: {
                            address: "Razorpay Corporate office"
                        },
                        theme: {
                            color: "#3399cc"
                        }
                    };
                    var pay = new window.Razorpay(options);
                    pay.open();
                }
            } else {
                toast.error(result.data.message);
                setLoading(false);
            }
        }
        setLoading(false);
    }

    return (
        <Fragment>
        {loading && <Transparentspinner />}
        <div className='bg-light pt-5' style={{height:'100%'}}>

            <ItemDescription
             ref={componentRef} 
             idValue={invoiceDetail}
             statusColor = {statusColor}
             />
            <Col sm="12" className="text-center">
                <Btn attrBtn={{ color: 'primary', className: 'me-2', onClick: handlePrint }}>
                    Print
                </Btn>
                {invoiceDetail.payment_status !== 'paid' && (
                    <Btn attrBtn={{ color: 'secondary', className: 'me-2', onClick: () => handlePayment() }}>
                    {AddPayments}
                </Btn>
                )}
            </Col>
            </div>
        </Fragment>
    );
};

export default PrintComponent;